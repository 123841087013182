<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- 版心 -->
    <div id="content_box">
      <!-- 视频 -->
      <div class="video_box">
        <ul class="video_title copyy">
          <li style="width:75%">{{ video_title }}</li>
          <span @click="copyall">
            <img src="../../../assets/img/fuzhi@2x.png" alt /> 复制播放链接
          </span>
        </ul>
        <div class="video_box_down">
          <div class="video_left">
            <!-- 视频播放器 -->
            <div id="play">
              <div id="player"></div>
            </div>
          </div>
          <div class="video_right">
            <!-- 视频列表 -->
            <ul id="video_list">
              <li v-for="item in newArr" :key="item.id">
                <!-- 标题 -->
                <div id="title_box">
                  <img src="../../../assets/img/zhangjie.png" alt />
                  {{ item.name }}
                </div>
                <!-- 列表 -->
                <div
                  id="video_list_box"
                  :class="{ click_video: clickVideo == el.id }"
                  v-for="el in item.video_list"
                  :key="el.id"
                  @click="changeVideo(el)"
                >
                  <img src="../../../assets/img/bofang.png" alt />
                  <div>{{ el.name }}</div>
                  <!-- <img class="gif" src="../../../assets/img/video_gif.gif" alt=""> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 链接地址 -->
      <div style="background:#fff;width: 1200px;margin:0 auto;">
         <div class="link_box" style="margin-bottom:0;">
        <div>电脑播放链接:</div>
        <div>{{ `${url.baseUrl}/auditionInfo/3/${link_address}` }}</div>
        <button
          type="button"
          @click="copyText(`${url.baseUrl}/auditionInfo/3/${link_address}`)"
        >复制链接</button>
      </div>
      <div style="width: 1140px;height: 1px;background: #F5F5F5;margin: 0 auto;"></div>
      <div class="link_box" style="margin-top:0;">
        <div>手机播放链接:</div>
        <div>{{ `${url.wapUrl}/playVideo?video_id=${link_address}` }}</div>
        <button
          type="button"
          @click="copyText(`${url.wapUrl}/playVideo?video_id=${link_address}`)"
        >复制链接</button>
      </div>
      </div>
     
      <!-- 版心结尾 -->
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      course_id: "", //视频id
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      polyv_vid: "",
      video_url: "", // 视频地址
      video_title: "",
      link_address: "",
      skinLocation: 1,
      newArr: [],
      clickVideo: "",
    };
  },
  methods: {
    copyall(){
      var textarea  = document.createElement("textarea"); // js创建一个input输入框
      textarea .value = `欢迎您观看勤学试听课程\n【${this.video_title}】\n【手机端播放】${this.url.wapUrl}/playVideo?video_id=${this.link_address}\n【电脑端播放】${this.url.baseUrl}/auditionInfo/3/${this.link_address}`; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(textarea ); // 将输入框暂时创建到实例里面
      textarea .select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(textarea ); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message.success({
        duration: 1500,
        message: "复制成功!",
      });
    },
    // 点击复制
    copyText(val) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = val; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message.success({
        duration: 1500,
        message: "复制成功!",
      });
    },

    // 获取试听课程详情视频
    getVideo() {
      var data = {
        // type: 2,
        course_id: this.course_id,
      };
      api.course_detail(data).then((res) => {
        if (res.data.data.info.polyv_vid && res.data.data.info.polyv_vid != '') {
          this.polyv_vid = res.data.data.info.polyv_vid;
        } else {
          this.video_url = res.data.data.info.video_url;
        }
        this.video_title = res.data.data.info.name.replace(
          /^试听[- ]+([^- ])(.*)/,
          "$1$2"
        );
        this.link_address = this.course_id
        this.getTwoNav(res.data.data.info.two_coursecate_id);
        this.loadPlayerScript(this.loadPlayer);
      });
    },
    // 二级导航
    getTwoNav(id) {
      // this.two_bar = {}
      this.video_cate_list = {};
      var data = {
        pid: id,
      };
      api.course_cate(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.getCourse(id);
          // var video_cate_list = [];
          this.two_bar = res.data.data;
          this.course = res.data.data;
          this.is_two = res.data.data[0].course_category_id;
        }
      });
    },

    // 试听课程接口
    getCourse(course_category_id) {
      var data = {
        course_category_id: course_category_id,
        type: 2,
        page_size: 100,
      };
      api.courses(data).then((res) => {
        this.newArr = [];
        if (parseInt(res.data.code) === 1) {
          this.course.forEach((el) => {
            el.video_list = [];
            res.data.data.list.map(function (item) {
              if (el.course_category_id == item.course_category_id) {
                if (item) {
                  item.name = item.name.replace(
                    /^试听[- ]+([^- ])(.*)/,
                    "$1$2"
                  );
                  el.video_list.push(item);
                } else {
                }
              }
            });
            this.newArr.push(el);
            this.$forceUpdate();
          });
          this.newArr = this.newArr.filter(
            (item) => item.video_list.length !== 0
          );
        }
      });
    },

    async getVidToken(vid) {
      // var getToken = async function() {
      // 调接口
      var data = {
        // token: localStorage.getItem("token"),
        vid: vid,
      };
      var token = "";
      await api
        .play_safe(data)
        .then((res) => {
          if (parseInt(res.data.code) == 1) {
            token = res.data.data.token;
          }
        })
        .catch((error) => {
          // return "";
        });
      return token;
    },
    // 保利威视频事件
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      }
    },
    loadPlayer() {
      // setTimeout(() => {
      const polyvPlayer = window.polyvPlayer;
      let options = {
        wrap: "#player",
        width: 900,
        height: 500,
        logo: {
          logo_width: 'auto', //Logo宽 默认 'auto'
          logo_height: 'auto', //Logo高 默认 'auto'
          logo_url: require('../../../assets/img/video_logo.png'), // Logo图片URL
          logo_pos: 2, //Logo显示位置，取值范围为：{0,1,2,3,4}，分别代表：隐藏、左上、右上、左下、右下
          logo_link: '', // 点击Logo的跳转链接 (选填)
          logo_alpha: 100, //Logo透明度，取值范围：(0,100)
          logoOffset: '95,5',//Logo偏移量，单位：百分比。坐标原点为播放器左上角，只在Logo位置为左上、右上时生效
          logo_resize: true //默认值为false，为true时会根据播放器窗口大小动态调整Logo的尺寸，若设置了logo宽高则该参数不生效
        }
      };
      if (this.polyv_vid != "") {
        options.vid = this.polyv_vid;
        options.playsafe = this.getVidToken(this.polyv_vid);
      } else {
        options.url = this.video_url;
      }
      console.log(options)
      this.player = polyvPlayer(options);
      this.player.on("s2j_onPlayOver", () => {
        //订阅播放器初始化完毕事件
        // this.player.changeVid(option);
        // player.j2s_seekVideo(60); //视频seek到第60秒
      });
    },
    // 点击切换视频
    changeVideo(el) {
      this.video_title = el.name;
      const option = {};
      if (el.polyv_vid && el.polyv_vid != '') {
        this.polyv_vid = el.polyv_vid;
        option.vid = el.polyv_vid;
        option.playsafe = this.getVidToken(el.polyv_vid);
      } else {
        this.video_url = el.video_url;
        option.url = el.video_url;
      }
      console.log(option)
      this.player.changeVid(option);
      this.clickVideo = el.id;
      this.link_address = el.id
    },

    // copy() {
    //   // this.$copyText(this.link_address)
    //   this.$copyText(this.link_address).then(
    //     function (e) {
    //       alert("已复制");
    //     },
    //     function (e) {
    //       alert("复制失败");
    //     }
    //   );

    //   // this.link_address = "http://127.0.0.1:5501/pc/phone.html?info_id=" + this.course_id
    // },
  },
  created() {
    // 当前页面id
    this.course_id = this.$route.params.info_id;
    this.getVideo();
  },
};
</script>

<style lang="scss" scoped>
#content_box {
  width: 100%;
  /* height: 1066px; */
  background: #f5f5f5;
  overflow: hidden;
}

.video_box {
  width: 1200px;
  /* height: 896px; */
  margin: 54px auto 0;
}
// 视频播放器标题
.video_box .video_title {
  width: 100%;
  height: 90px;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000b2f;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.video_box .video_title li {
  width: 1108px;
  margin-left: 23px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
// 视频播放器
.video_box_down {
  display: flex;
}
#play {
  width: 900px;
  height: 500px;
  background: #fff;
}
#play video {
  width: 100%;
  height: 100%;
}
// 右边
.video_right {
  width: 300px;
  max-height: 500px;
  background: #fff;
  border-top: 1px solid #eee;
  overflow-y: auto;
  overflow-x: hidden;
}
.video_right::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.video_right::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.video_right::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #c8c8cb;
}
// 章节标题
#title_box {
  // width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  // margin-left: 13px;
  // padding-left: 13px;
  margin-left: 13px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000b2f;
  border-bottom: 1px solid #eee;
}
#title_box img {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}
// 视频标题
#video_list_box {
  width: 287px;
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 13px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
#video_list_box:last-child {
  border-bottom: none;
}
#video_list_box img {
  margin: 0 10px 0 0px;
}
#video_list_box div {
  width: 230px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#video_list_box:hover {
  background: #e9f2ff;
  // color: #3E7EFF;
}
.gif {
  width: 16px;
  height: 16px;
}
.click_video {
  background: #e9f2ff;
  color: #3e7eff;
}
/* 链接地址 */

.link_box {
  position: relative;
  width: 1200px;
  height: 70px;
  background: #fff;
  margin: 40px auto 79px;
  display: flex;
  align-items: center;
}
.link_box div:nth-child(1) {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000b2f;
  margin: 0 20px 0 30px;
}

.link_box div:nth-child(2) {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #3e7eff;
}

.link_box button {
  position: absolute;
  right: 40px;
  width: 140px;
  height: 40px;
  background: #e9f2ff;
  /* opacity: 0.1; */
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #3e7eff;
  line-height: 40px;
  cursor: pointer;
}
/*正常情况下滑块的样式*/

.video_right::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在该类指向的控件上时滑块的样式*/

.video_right:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在滑块上时滑块的样式*/

.video_right::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*正常时候的主干部分*/

.video_right::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0);
  background-color: #eeeeee;
}

/*鼠标悬浮在滚动条上的主干部分*/

.video_right::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.01);
}
::v-deep #player div {
  white-space: normal !important;
}
.copyy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 10px 0px rgba(0, 5, 75, 0.08);
  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 11px 16px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border-radius: 30px;
    background: #3e7eff;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
